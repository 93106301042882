// http://example2.igli.site/


import  {items} from '../../../../contentCarousel/contentCarosel';
import DemoWrapper from "../../../../DemoWrapper/DemoWrapper";
import React, { MouseEvent, useRef } from 'react';
import IframeWrapper from "../../../../IFrame_Container/IFrameContainer";
import ImageLoader from "../../../../../Components/ImageLoader/ImageLoader";
import IFrameContainer from "../../../../IFrame_Container/IFrameContainer";

let CaroselItem : items =    {
    key : 1,
    navigationUrl : "https://www.vend88.com.au",
    Subject : "Vending Management System",
    tag :[ "Most Relevant"],
    Description : 
        <>
            <p style = {{}}>
                Developed a comprehensive vending machine management system with a web-based platform for ordering and an Android application for vending machine control and operations.
                The web system facilitates user registration, order placement, and generating pickup codes for order retrieval at the vending machine.
                The system also includes product management, inventory / sales tracking, stock-management, and vending machine configuration (e.g., vending slots).


                {/* Developed an integrated vending machine system featuring a web-based ordering platform and an Android application for vending machine control. 
                The web system facilitates user registration, order placement, and generation of pickup codes for retrieval at the vending machine. 
                Additionally, it includes account management functionalities for both shoppers and administrators, allowing for streamlined product management. */}


                {/* A vending machine system incorporating both a web-based ordering platform and an Android application for vending machine control. 
                The web system enables users to register, place orders, generate pickup codes for retrieval at the vending machine, alongside management for shoppers and adminstrators
                (i.e. product management).  */}
            </p>
            <p>
                {/* The vending machine (hardware) is managed and controlled remotely through the main-server, which is access through the Android application via api-calls. 
                The Android app is built using React Native, and communicates to the main-server via REST. */}
                {/* The vending machine hardware is remotely managed and controlled via the main server, accessible through the Android application using API calls. The Android app, built using React Native, communicates with the main server through REST API for seamless interaction and control. */}

            </p>
            <p>
                <span style = {{textDecoration:'underline'}}> Note </span> : This project is still in production.  Android apk is available <a href = "https://dl3wesfkybaqd.cloudfront.net/#download">Here. </a> (Function is limited, as hardware binding is required).
            </p>
            <p style = {{fontWeight:"800", textDecoration:"underline"}}> Features: </p>

            <ol>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}>  Web-Based Ordering System : </span>
                
                        Implemented a user-friendly web platform enabling users to order products from the vending machine, complemented by robust product, stock and vending machine management functionalities tailored for administrative users.

                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}> Android Control Application: </span>
                        Developed an Android application using React Native to remotely control and manage the vending machine, ensuring seamless synchronization managemnt system.
                    </li>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}> Integration of Hardware and Software : </span>
                        Seamlessly integrated the web ordering system, Android control application, and vending machine hardware components for cohesive operation.
                    </li>
            </ol>
            {/* <p style = {{fontWeight:"800", color:"black", textDecoration:"underline"}}> <a style={{color:"black"}} href = "http://www.vendingapplication.io.s3-website-ap-southeast-2.amazonaws.com/#download">Android APK </a> </p> */}

            <p style = {{fontWeight:"800", textDecoration:"underline"}}> Management Portal : </p>
            <ul>
                    <li style={{margin:'5px', boxSizing:'border-box'}}>
                        <span style = {{textDecoration:'underline'}}> 
                            <a target="_blank" style = {{textDecoration:'none'}} href='https://www.vend88.com.au/#admin'> Administration Portal </a>
                         </span>
                        <ul>
                            <li> Email : root@gmail.com</li>
                            <li> Password : somePassword321</li>
                        </ul>
                    </li>
                    {/* <li>
                        
                        <div style = {{border:'1px solid white', width:'500px', height :'750px', overflow:"hidden", background:"white"}}>
                            <IframeWrapper PageURL={"http://www.vendingapplication.io.s3-website-ap-southeast-2.amazonaws.com/#admin"}/>
                        </div>


                    </li> */}
            </ul>

            {/* <DemoWrapper Title={"Demo Project"}> */}
            {/* </DemoWrapper>  */}


            <p style = {{fontWeight:"800", textDecoration:"underline", marginTop:"5%", textAlign:"left"}}> Vend Android Application </p>

                <div style = {{width:'100%',  position:'relative', border:'1px solid white',}}>
                    <p style = {{position:'absolute',  color :'black', bottom:"0", left:"2%", fontWeight:"800"}}></p>
                    <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/demo.JPG"}/>
                </div>
                <div style = {{width:"100%", boxSizing:'border-box', display:"grid", gridTemplateColumns:"50% 50%"}}>
                    <div style = {{width:"100%",  boxSizing:'border-box'}}>
                        <div style = {{width:'100%',  position:'relative', border:'1px solid white', }}>
                            <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/demo1.jpeg"}/>
                        </div>
                    </div>
                    <div style = {{width:"100%", boxSizing:'border-box'}}>
                        <div style = {{width:'100%',  position:'relative', border:'1px solid white', }}>
                            <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/demo2.jpeg"}/>
                        </div>
                    </div>
                    <div style = {{width:"100%", boxSizing:'border-box'}}>

                        <div style = {{width:'100%',  position:'relative', border:'1px solid white', }}>
                            <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/demo3.jpeg"}/>
                        </div>
                    </div>
                    <div style = {{width:"100%",  boxSizing:'border-box'}}>
                        <div style = {{width:'100%',  position:'relative', border:'1px solid white', }}>
                            <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/demo4.jpeg"}/>
                        </div>
                    </div>
                    <div style = {{width:"100%",  boxSizing:'border-box'}}>
                        <div style = {{width:'100%',  position:'relative', border:'1px solid white', }}>
                            <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/demo5.jpeg"}/>
                        </div>
                    </div>
                    <div style = {{width:"100%",  boxSizing:'border-box'}}>
                        <div style = {{width:'100%',  position:'relative', border:'1px solid white', }}>
                            <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/demo6.jpeg"}/>
                        </div>
                    </div>
                    <div style = {{width:"100%",  boxSizing:'border-box'}}>
                        <div style = {{width:'100%',  position:'relative', border:'1px solid white', }}>
                            <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/demo7.jpeg"}/>
                        </div>
                    </div>
                    <div style = {{width:"100%",  boxSizing:'border-box'}}>
                        <div style = {{width:'100%',  position:'relative', border:'1px solid white', }}>
                            <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/demo8.jpeg"}/>
                        </div>
                    </div>
                </div>
                <p style = {{fontWeight:"800", textDecoration:"underline", marginTop:"10%", textAlign:"left"}}> Web Management System </p>
                <div style = {{width:"100%", boxSizing:'border-box', display:"grid", gridTemplateColumns:"50% 50%"}}>



                    <div style = {{width:'100%',  position:'relative', border:'1px solid black', }}>
                        <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}} url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/menu.png"}/>
                    </div>
                    <div style = {{width:'100%',  position:'relative', border:'1px solid black', }}>
                        <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}} url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/cart.png"}/>
                    </div>
                    <div style = {{width:'100%',  position:'relative', border:'1px solid black', }}>
                        <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}} url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/checkout.png"}/>
                    </div> 
                    <div style = {{width:'100%',  position:'relative', border:'1px solid black', }}>
                        {/* <p style = {{position:'absolute', color :'black', bottom:"0", left:"2%",  fontWeight:"800"}}>Vending machine configuration Page</p> */}
                        <ImageLoader  styling={{width:"100%", height:"auto", minHeight:"150px"}} url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/vendconfig.png"}/>
                        {/* <img style = {{width:"100%", background:'red', height :'auto'}} src = "./images/myresume_Content/Project_Demo/ShoppingSystem/vendconfig.png" /> */}
                    </div>
                    <div style = {{width:'100%',  position:'relative', border:'1px solid black', }}>
                        {/* <p style = {{position:'absolute',  color :'black', bottom:"0", left:"2%", fontWeight:"800"}}>Vend Management Page</p> */}
                        <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/vend.png"}/>

                        {/* <img style = {{width:"100%", background:'red', height :'auto'}} src = "./images/myresume_Content/Project_Demo/ShoppingSystem/vend.png" /> */}
                    </div>
                    <div style = {{width:'100%',  position:'relative', border:'1px solid black', }}>
                        {/* <p style = {{position:'absolute',  color :'black', bottom:"0", left:"2%", fontWeight:"800"}}>Product Management Page</p> */}
                        <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/product.png"}/>
                        {/* <img style = {{width:"100%", background:'red', height :'auto'}} src = "./images/myresume_Content/Project_Demo/ShoppingSystem/product.png" /> */}
                    </div>
                    {/* <div style = {{width:'100%',  position:'relative', border:'1px solid black', }}>
                        <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/user.png"}/>

                    </div> */}
                    <div style = {{width:'100%',  position:'relative', border:'1px solid black', }}>
                        {/* <p style = {{position:'absolute',  color :'black', bottom:"0", left:"2%", fontWeight:"800"}}></p> */}
                        <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/ordersumm.png"}/>

                        {/* <img style = {{width:"100%", background:'red', height :'auto'}} src = "./images/myresume_Content/Project_Demo/ShoppingSystem/ordersumm.png" /> */}
                    </div>
                
                    <div style = {{width:'100%',  position:'relative', border:'1px solid black', }}>
                        {/* <p style = {{position:'absolute',  color :'black', bottom:"0", left:"2%", fontWeight:"800"}}></p> */}
                        <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/transactions.png"}/>

                        {/* <img style = {{width:"100%", background:'red', height :'auto'}} src = "./images/myresume_Content/Project_Demo/ShoppingSystem/demo.JPG" /> */}
                    </div>
                    <div style = {{width:'100%',  position:'relative', border:'1px solid black', }}>
                        {/* <p style = {{position:'absolute',  color :'black', bottom:"0", left:"2%", fontWeight:"800"}}></p> */}
                        <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/StatisticsVend.png"}/>
                    </div>
                  
                   
                    <div style = {{width:'100%',  position:'relative', border:'1px solid black', }}>
                        <ImageLoader styling={{width:"100%", height:"auto", minHeight:"150px"}}  url = {"./images/myresume_Content/Project_Demo/ShoppingSystem/addons.png"}/>
                    </div>
            </div>
        </>,
    img_Url:"./images/Project_Images/vending.jpg", 
    show_Image: false,           
    file_List : [
        {
            key : 1,
            FileTitle : "",
            content : ""
        }
    ]
}

export default CaroselItem;